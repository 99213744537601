async function download(link) {
  const { $axios, $showToast } = useNuxtApp();

  try {
    const response = await $axios.get(link, {
      responseType: "blob",
    });
    const blob = new Blob([response.data], { type: "application/zip" });
    location.href = URL.createObjectURL(blob);
  } catch (err) {
    $showToast(err?.message, "error");
  }
}

function getDate(str) {
  const date = new Date(str);
  var year = date.toLocaleString("default", { year: "numeric" });
  var month = date.toLocaleString("default", { month: "2-digit" });
  var day = date.toLocaleString("default", { day: "2-digit" });

  var hour = date.toLocaleString("default", { hour: "2-digit" });
  var minute = date.toLocaleString("default", { minute: "2-digit" });
  var second = date.toLocaleString("default", { second: "2-digit" });

  return (
    day +
    "." +
    month +
    "." +
    year +
    " " +
    ("0" + hour).slice(-2) +
    ":" +
    ("0" + minute).slice(-2)
  );
}

export default {
  download,
  getDate,
};
